import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const image_w = 345
const image_h = 650

const ImagesTop = (props) => (
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner">
      <div className="carousel-item active">
        <StaticImage
          src="../../../images/huey-mobile-43-800.jpeg"
          alt="huey mobile"
          className="d-block w-100"
          objectFit="fixed"
          layout="fullWidth"
          transformOptions={{fit: "contain"}}
          backgroundColor="white"
          aspectRatio={image_w / image_h}
          width={image_w}
          height={image_h}
        />
      </div>
      <div className="carousel-item">
        <StaticImage
          src="../../../images/App_Alert-800.jpeg"
          alt="huey mobile"
          className="d-block w-100"
          objectFit="contain"
          layout="fullWidth"
          transformOptions={{fit: "contain"}}
          backgroundColor="white"
          aspectRatio={image_w / image_h}
          width={image_w}
          height={image_h}
        />
      </div>
      <div className="carousel-item">
        <StaticImage
          src="../../../images/App_Dashboard-800.jpeg"
          alt="huey mobile"
          className="d-block w-100"
          objectFit="contain"
          layout="fullWidth"
          transformOptions={{fit: "contain"}}
          backgroundColor="white"
          aspectRatio={image_w / image_h}
          width={image_w}
          height={image_h}
        />
      </div>
      <div className="carousel-item">
        <StaticImage
          src="../../../images/App_Dashboard-800.jpeg"
          alt="huey mobile"
          className="d-block w-100"
          objectFit="contain"
          transformOptions={{fit: "contain"}}
          backgroundColor="white"
          layout="fullWidth"
          aspectRatio={image_w / image_h}
          width={image_w}
          height={image_h}
        />
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
)

export default ImagesTop

