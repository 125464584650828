import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from "./navbar"

// props.pageContext.frontmatter

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={'container-lg p-0 page-container d-flex flex-column '+props.className}>
      <Navbar siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className="flex-shrink-0">
        {props.children}
      </main>
      <footer className="footer mt-auto py-4">
        <div className="container col text-center">
          <span className="">COPYRIGHT © 2021 HUEY CO - ALL RIGHTS RESERVED.</span>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
